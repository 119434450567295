import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Container from '@mui/material/Container';
import FilterIcon from "@mui/icons-material/Filter";
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import TypeAvatar from '../comp/TypeAvatar';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import IconButton from '@mui/material/IconButton';
import Page from '../util/Page';
import ToolBar from '../util/ToolBar';
import {TabBar, TabPanel} from '../util/Tab';
import { ReactComponent as TableViewIcon } from '../util/tableView.svg';
import DataTable2, {Cell} from '../util/DataTable2';
import {Field} from '../model/fields';
import {removeTemplate as removeReportTemplate} from '../report/state';
import {removeTemplate as removeSheetTemplate} from '../sheet/state';
import {
  archiveTemplate,
  clearTemplate,
  copyTemplate,
  pageReportTemplates,
  pageSheetTemplates,
  renameTemplate,
  sortReportTemplates,
  sortSheetTemplates,
  updateTemplateTypes
} from './state';
import {patchTemplate as patchReportTemplate} from '../report/state';
import {patchTemplate as patchSheetTemplate} from '../sheet/state';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {pushSnack} from '../app/state';
import EditableLabel from '../util/EditableLabel';
import {ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND, propertyNameShort} from '../model/propertyTypes';
import {hasRole, ROLE_EDITOR} from '../auth/roles';

const FIELDS =  [Field.TITLE, Field.TYPE, Field.CREATED, Field.UPDATED];

const isTemplateArchived = template => template.archived === true;

const DateCell = ({field, obj, ...props}) => {
    const author = obj[`${field.path}_by`];
    return <Cell {...props}
                 primary={<Typography>{field.render(obj)}</Typography>}
                 secondary={<Typography variant="subtitle2">{field.render(obj, {fromNow: true})}</Typography>}
                 avatar={author && <Avatar user={author} sx={{ width: 32, height: 32, fontSize: "1rem" }}/> }
    />;
}
const TemplateActions = ({template, doDelete, doCopy, doArchive}) => {
    const dispatch = useDispatch();
    const [isDeleting, setDeleting] = useState(false);
    const [isCopying, setCopying] = useState(false);
    const [isArchiving, setArchiving] = useState(false);
    const [templateTitle, setTemplateTitle] = useState(`Copy of ${template.title}`);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCopyDialog, setShowCopyDialog] = useState(false);

    const isArchived = isTemplateArchived(template);
    const isWorking = isDeleting || isCopying || isArchiving;
    const handleDelete = () => {
        setDeleting(true);
        doDelete()
            .then(() => {
                closeDeleteDialog();
                setDeleting(false);
                dispatch(pushSnack({message: `Template ${template.title} deleted.`}));
            });
    }

    const handleCopy = () => {
        setCopying(true);
        doCopy(templateTitle)
            .then(() => {
                closeCopyDialog();
                setCopying(false);
                dispatch(pushSnack({message: `Template ${templateTitle} created.`}));
            });
    }

    const openDeleteDialog = () => {
        setShowDeleteDialog(true);
    }

    const closeDeleteDialog = () => {
        setShowDeleteDialog(false);
    }

    const openCopyDialog = () => {
        setShowCopyDialog(true);
    }

    const closeCopyDialog = () => {
        setShowCopyDialog(false);
    }

    const handleArchive = () => {
      setArchiving(true);
      doArchive()
          .then(() => {
            setArchiving(false);
          });
    }

    return (
        <>
            <IconButton
                disabled={isWorking}
                onClick={openDeleteDialog}
                size="large">
                {isDeleting === false ? <Tooltip title="Delete Template">
                    <DeleteIcon />
                </Tooltip> : <CircularProgress size={24}/>}
            </IconButton>
            <IconButton
                disabled={isWorking}
                onClick={openCopyDialog}
                size="large">
                {isDeleting === false ? <Tooltip title="Copy Template">
                    <ContentCopyIcon />
                </Tooltip> : <CircularProgress size={24}/>}
            </IconButton>
            <IconButton
                disabled={isWorking}
                onClick={handleArchive}
                size="large">
              {isArchiving === false ? <Tooltip title={`${isArchived?'Unarchive':'Archive'} Template`}>
                {isArchived ? <UnarchiveIcon/> : <ArchiveIcon/>}
              </Tooltip> : <CircularProgress size={24}/>}
            </IconButton>
            <Dialog open={showDeleteDialog} onClose={closeDeleteDialog} fullWidth maxWidth={"sm"}>
                <DialogTitle>Delete Template?</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText sx={{mb:2}}>
                        Are you sure you want to delete the template <strong>{template.title}</strong>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} disabled={isDeleting}>Cancel</Button>
                    <Button onClick={handleDelete} disabled={isDeleting} startIcon={isDeleting ? <CircularProgress size={24}/> : null}>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showCopyDialog} onClose={closeCopyDialog} fullWidth maxWidth={"sm"}>
                <DialogTitle>Copy Template</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText sx={{mb:2}}>
                        Please specify a name for the new template.
                    </DialogContentText>
                    <TextField
                        value={templateTitle} onChange={evt => setTemplateTitle(evt.target.value)}
                        autoFocus
                        margin="dense"
                        label="Template Name"
                        fullWidth
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCopyDialog}>Cancel</Button>
                    <Button onClick={handleCopy}>Copy</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const TemplateLabel = ({value, onChange}) => {
  return <EditableLabel value={value} defaultValue={value} onChange={onChange} withButton={false} withClick={true} size="small" />
}

const TemplateTypes = ({template, onChange}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [types, setTypes] = useState(template.types || []);

  const toggle = type => {
    if (types.includes(type)) {
      setTypes(types.filter(t => t !== type));
    } else {
      setTypes([...types, type]);
    }
  }

  const update = () => {
    onChange(types);
    setShowDialog(false);
  };

  return (
      <>
        <AvatarGroup onClick={() => setShowDialog(true)}>
          {(template.types||[]).map(type => <TypeAvatar key={type} type={type} clickable={true}/>)}
        </AvatarGroup>
        {showDialog && <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle>Comp Types for Template</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Select the comp types below that this template is applicable to.
            </Typography>
            <FormGroup>
            {
              [ICI, MULTI_FAMILY, VACANT_LAND, LEASE, RESIDENTIAL_RENT].map(type =>
                  <FormControlLabel key={type} label={propertyNameShort(type)} control={
                    <Checkbox checked={types.includes(type)} onClick={() => toggle(type)}/>
                  } />)
            }
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)} color="secondary" autoFocus>
              Cancel
            </Button>
            <Button onClick={update} color="primary" disabled={!(
                (types.length !== (template.types?.length||0)) || types.some(t => !(template.types?.includes(t)||false))
            )}>
              Add
            </Button>
          </DialogActions>
        </Dialog>}
      </>
  )
}

const ReportsPanel = ({...props}) => {
    const dispatch = useDispatch();
    const count = useSelector(state => state.app.org.num_report_templates || -1)
    const list = useSelector(state => state.template.reports.list || []);
    const isEditor = useSelector(state => hasRole(state.auth, ROLE_EDITOR));

    useEffect(() => {
        if (list.page === -1) dispatch(pageReportTemplates(true))
    }, [dispatch, list]);

    const handlePage = forward => {
        return dispatch(pageReportTemplates(forward));
    };
    const handleSort = (field, order) => {
        return dispatch(sortReportTemplates({field, order}));
    }

    const handleRemove = async t => {
        await dispatch(removeReportTemplate(t.id)).unwrap();
        dispatch(clearTemplate(t));
    }

    const handleCopy = async (t, title) => {
        await dispatch(copyTemplate({template: t, title, type: 'report'})).unwrap();
    }

    const handleRename = async (t, title) => {
      await dispatch(renameTemplate({template: t, title, type: 'report'})).unwrap()
          .then(() => dispatch(pushSnack({message: `Template ${t.title} renamed to ${title}`})));
    }

    const handleUpdateTypes = async (t, types) => {
      await dispatch(updateTemplateTypes({template: t, types, type: 'report'})).unwrap()
          .then(() => dispatch(pushSnack({message: `Template ${t.title} updated`})));
    }

  const handleArchive = async (t) => {
    await dispatch(archiveTemplate({template: t, type: 'report'})).unwrap()
        .then(() => {
          dispatch(pushSnack({message: `Template ${t.title} ${isTemplateArchived(t)?'un':''}archived`}));
          dispatch(patchReportTemplate({id: t.id, patch: {archived: !isTemplateArchived(t)}}));
        });
  }

    return (
        <TabPanel {...props} className="ReportTemplatesPanel">
            <DataTable2 data={list} fields={FIELDS} noun="report" totalCount={count} onPage={handlePage} onSort={handleSort}
                        canSort={(field) => {
                          return field !== Field.TYPE;
                        }}
                        cellRenderer={(t, field, props) => {
                            if (field === null) {
                                return <Cell/>
                            }
                            if (field === Field.TYPE) {
                                return <Cell avatar={<TemplateTypes template={t} onChange={(newTypes => handleUpdateTypes(t, newTypes))}/> } {...props}>
                                </Cell>;
                            }
                            if (field === Field.TITLE) {
                                const title = field.get(t);
                                return <Cell primary={<TemplateLabel value={title} onChange={(newTitle) => handleRename(t, newTitle)} />}
                                             sx={isTemplateArchived(t)?{
                                               color: 'text.disabled',
                                               fontStyle: 'italic'
                                             }:{}}
                                             {...props}/>
                            }
                            if (field === Field.CREATED) {
                                return <DateCell obj={t} field={Field.CREATED} {...props}/>
                            }

                            if (field === Field.UPDATED) {
                                return <DateCell obj={t} field={Field.UPDATED} {...props}/>
                            }
                        }}
                        actionRenderer={isEditor ? t => <TemplateActions template={t}
                            doDelete={() => handleRemove(t)}
                            doCopy={(title) => handleCopy(t, title)}
                            doArchive={() => handleArchive(t)}
                        /> : null}
            />
        </TabPanel>
    )
}

const SheetsPanel = ({...props}) => {
    const dispatch = useDispatch();
    const count = useSelector(state => state.app.org.num_sheet_templates || -1)
    const list = useSelector(state => state.template.sheets.list || []);
    const isEditor = useSelector(state => hasRole(state.auth, ROLE_EDITOR));

    useEffect(() => {
        if (list.page === -1) dispatch(pageSheetTemplates(true))
    }, [dispatch, list]);

    const handlePage = forward => {
        return dispatch(pageSheetTemplates(forward));
    };
    const handleSort = (field, order) => {
        return dispatch(sortSheetTemplates({field, order}));
    }

    const handleRemove = async (t) => {
        await dispatch(removeSheetTemplate(t.id)).unwrap()
        await dispatch(clearTemplate(t));
    }

    const handleCopy = async (t, title) => {
        await dispatch(copyTemplate({template: t, title, type: 'sheet'})).unwrap();
    }

    const handleRename = async (t, title) => {
      await dispatch(renameTemplate({template: t, title, type: 'sheet'})).unwrap()
          .then(() => dispatch(pushSnack({message: `Template ${t.title} renamed to ${title}`})));
    }

    const handleUpdateTypes = async (t, types) => {
      await dispatch(updateTemplateTypes({template: t, types, type: 'sheet'})).unwrap()
          .then(() => dispatch(pushSnack({message: `Template ${t.title} updated`})));
    }

    const handleArchive = async (t) => {
      await dispatch(archiveTemplate({template: t, type: 'sheet'})).unwrap()
          .then(() => {
            dispatch(pushSnack({message: `Template ${t.title} ${isTemplateArchived(t)?'un':''}archived`}));
            dispatch(patchSheetTemplate({id: t.id, patch: {archived: !isTemplateArchived(t)}}));
          });
    }

    return (
        <TabPanel {...props} className="SheetTemplatesPanel">
            <DataTable2 data={list} fields={FIELDS} noun="report" totalCount={count} onPage={handlePage} onSort={handleSort}
                        canSort={(field) => {
                          return field !== Field.TYPE;
                        }}
                        cellRenderer={(t, field, props) => {
                            if (field === null) {
                                return <Cell/>
                            }
                            if (field === Field.TYPE) {
                                return <Cell avatar={<TemplateTypes template={t} onChange={(newTypes) => handleUpdateTypes(t, newTypes)} />} {...props}>
                                </Cell>;
                            }
                            if (field === Field.TITLE) {
                              const title = field.get(t);
                              return <Cell primary={<TemplateLabel value={title} onChange={(newTitle) => handleRename(t, newTitle)} />}
                                           sx={isTemplateArchived(t)?{
                                             color: 'text.disabled',
                                             fontStyle: 'italic'
                                           }:{}}
                                           {...props}/>
                            }
                            if (field === Field.CREATED) {
                                return <DateCell obj={t} field={Field.CREATED} {...props}/>
                            }

                            if (field === Field.UPDATED) {
                                return <DateCell obj={t} field={Field.UPDATED} {...props}/>
                            }
                        }}
                        actionRenderer={isEditor ? t => <TemplateActions template={t}
                                                  doDelete={() => handleRemove(t)}
                                                  doCopy={(title) => handleCopy(t, title)}
                                                  doArchive={() => handleArchive(t)}
                        /> : null}
            />
        </TabPanel>
    )
}
const TemplatesPage = () => {
    return (
        <Page className="TemplatePage">
            <ToolBar title="Templates">
            </ToolBar>
            <Container fixed>
                <TabBar tabs={[
                    [{label: 'Reports', icon: <FilterIcon/>}, p => <ReportsPanel {...p}/>],
                    [{label: 'Sheets', icon: <SvgIcon component={TableViewIcon}/>}, p => <SheetsPanel {...p}/>]
                ]}/>
            </Container>
        </Page>
    )
};

export default TemplatesPage;
